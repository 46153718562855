<template>
    <div class="group-control">
        <h1 class="group-control-heading">{{ $t("control-group.title") }}</h1>

        <control-group-article title="control-group.totalCallStatistic">
            <template v-slot:card>
                <control-group-card
                    v-for="(
                        {
                            phone,
                            phoneSub,
                            icon,
                            theme,
                            waitCount,
                            allContacts,
                            longestWait,
                            servedContacts,
                            operatorsOnSystem,
                            notReadyOperators,
                        },
                        index
                    ) in cardsArray"
                    :key="index"
                    :phone="phone"
                    :phoneSub="phoneSub"
                    :icon="icon"
                    :theme="theme"
                    :wait-count="waitCount"
                    :all-contacts="allContacts"
                    :longest-wait="longestWait"
                    :served-contacts="servedContacts"
                    :operators-on-system="operatorsOnSystem"
                    :not-ready-operators="notReadyOperators"
                />
            </template>
        </control-group-article>

        <control-group-article title="control-group.totalChatStatistic">
            <template v-slot:card>
                <control-group-card
                    v-for="(
                        {
                            title,
                            icon,
                            waitCount,
                            allChats,
                            servedChats,
                            readyAgents,
                            notReadyAgents,
                        },
                        index
                    ) in chatsArray"
                    :key="index"
                    is-chat
                    :phone="title"
                    :icon="icon"
                    :wait-count="waitCount"
                    :all-contacts="allChats"
                    :served-contacts="servedChats"
                    :operators-on-system="readyAgents"
                    :not-ready-operators="notReadyAgents"
                />
            </template>
        </control-group-article>

        <control-group-operators-list
            v-if="operatorList.length > 0"
            v-model="activeTabKey"
            :operatorList="operatorList"
            :is-empty-data="false"
            :tab-labels="tabLabels"
            :stats="resourceStats"
            @update:tab="loadOperators"
        />
    </div>
</template>

<script>
import { mapState } from "vuex";
import ControlGroupCard from "@/components/pages/AdminHomePage/Widgets/Card.vue";
import ControlGroupArticle from "@/components/pages/AdminHomePage/Widgets/GroupArticle.vue";
import ControlGroupOperatorsList from "@/components/pages/AdminHomePage/Widgets/OperatorsList/index.vue";
import socket from "../../../services/StatistiscsSocket";
import {
    StatisticsProxyClass,
    ResourceStats,
} from "../../../helpers/StatisticsOperator";
import oCiscoConfig from "../../../config/cisco";
import finasseAgent from "../../../services/finesse-agent";

export default {
    name: "group-control",
    components: {
        ControlGroupOperatorsList,
        ControlGroupArticle,
        ControlGroupCard,
    },
    data() {
        return {
            activeTabKey: "",
            operatorList: [],
            chatStatistics: [],
            voiceStatistics: [],

            resourceStats: [],

            ciscoConfig: oCiscoConfig,
        };
    },
    computed: {
        ...mapState({
            userTeams(state) {
                return [...state.userTeams];
            },
        }),
        cardsArray() {
            return this.voiceStatistics.map((el) => {
                return {
                    phone: this.ciscoConfig.VoiceGroupsInfo[el.id].name,
                    phoneSub: "",
                    icon: this.ciscoConfig.VoiceGroupsInfo[el.id].icon,
                    theme: "primary",
                    waitCount: el.VoiceIAQStats.nWaitingContacts,
                    allContacts: el.VoiceIAQStats.nTotalContacts,
                    longestWait:
                        el.VoiceIAQStats.longestCurrentlyWaitingCallStartTime,
                    servedContacts: el.VoiceIAQStats.nHandledContacts,
                    operatorsOnSystem: el.VoiceIAQStats.nResourcesLoggedIn,
                    notReadyOperators: el.VoiceIAQStats.nUnavailResources,
                };
            });
        },
        chatsArray() {
            return this.chatStatistics.map((el) => {
                return {
                    title: oCiscoConfig.ChatGroupsInfo[el.id].name,
                    icon: oCiscoConfig.ChatGroupsInfo[el.id].icon,
                    waitCount: el.ChatQueueStatistics.contactsQueued,
                    allChats: el.ChatQueueStatistics.contactsTotal,
                    servedChats: el.ChatQueueStatistics.contactsHandled,
                    readyAgents: el.ChatQueueStatistics.agentsLoggedIn,
                    notReadyAgents: el.ChatQueueStatistics.agentsNotReady,
                };
            });
        },
        tabLabels() {
            return {
                "all-operators": this.$t("dashboard.admin.tabs.all-operators"),
                calls: this.$t("dashboard.admin.tabs.calls"),
                chats: this.$t("dashboard.admin.tabs.chats"),
                telemarketing: this.$t("dashboard.admin.tabs.telemarketing"),
            };
        },
    },
    methods: {
        onMessage(message) {
            if (message.ChatQueueStatistics) {
                const oldMessageIdx = this.chatStatistics.findIndex(
                    (data) => data.id === message.id
                );
                if (oldMessageIdx > -1) {
                    this.chatStatistics.splice(oldMessageIdx, 1, message);
                } else {
                    this.chatStatistics.push(message);
                }
            } else if (message.VoiceIAQStats) {
                const oldMessageIdx = this.voiceStatistics.findIndex(
                    (data) => data.id === message.id
                );
                if (oldMessageIdx > -1) {
                    this.voiceStatistics.splice(oldMessageIdx, 1, message);
                } else {
                    this.voiceStatistics.push(message);
                }
            } else if (message.ResourceIAQStats) {
                const msg = ResourceStats.getParsedMessage(message);
                const oldMessageIdx = this.resourceStats.findIndex(
                    (data) => data.resourceId === message.id
                );
                if (oldMessageIdx > -1) {
                    this.resourceStats.splice(oldMessageIdx, 1, msg);
                } else {
                    this.resourceStats.push(msg);
                }
            }
        },

        loadStatistic() {
            const username = this.$store.state.auth.username;
            const oDriver = new StatisticsProxyClass(1, username).getDrivers();
            if (null !== oDriver) {
                oDriver.getCallback().apply(this);
            }
            const oDriverChat = new StatisticsProxyClass(
                6,
                username
            ).getDrivers();
            if (null !== oDriverChat) {
                oDriverChat.getCallback().apply(this);
            }
            const oDriverResource = new StatisticsProxyClass(
                2,
                username
            ).getDrivers();
            if (null !== oDriverResource) {
                oDriverResource.getCallback().apply(this);
            }
        },

        loadOperators(val, init = false) {
            finasseAgent.finesse
                .GetTeam(val)
                .then((response) => {
                    this.operatorList = response;

                    if (init) {
                        this.operatorStats()
                    }
                })
                .catch(() => {
                    this.operatorList = [];
                });
        },

        handleTabs() {
            if (this.userTeams.length === 1) {
                this.loadOperators(this.userTeams[0].id, true);
            }

            if (this.userTeams && this.userTeams.length > 0) {
                this.activeTabKey = this.userTeams[0].id;
            }
        },

        operatorStats() {
            socket.addGroupAndSubscribe(
                {
                    source: "ResourceIAQStats",
                    groups: [
                        this.operatorList.map((el) => el.loginId).join(","),
                    ],
                },
                this.onMessage
            );
        },
    },
    mounted() {
        this.loadStatistic();
        socket.subscribe(this.onMessage);

        setTimeout(() => {
            this.handleTabs();
        }, 1000);
    },
    beforeDestroy() {
        socket.unsubscribe(this.onMessage);

        socket.removeGroupAndUnsubscribe(
            {
                source: "ResourceIAQStats",
                groups: [this.operatorList.map((el) => el.loginId).join(",")],
            },
            this.onMessage
        );
    },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables";

.group-control {
    padding: 32px 48px;
    font-family: $font-primary;

    &-heading {
        color: var(--color-black);
        font-size: 36px;
        font-weight: 700;
        margin-bottom: 45px;
    }
}
</style>
