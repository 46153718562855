<template>
    <div class="operator-list">
        <h2 class="operator-list__heading">
            {{ $t("control-group.listOfOperators") }}
        </h2>

        <a-tabs
            class="operator-list__tabs"
            id="test"
            v-model="activeTabKey"
            :animated="false"
            @change="emit('update:tab', $event)"
        >
            <a-tab-pane
                v-for="tab in userTeams"
                :key="tab.id"
                :tab="tab.name"
                class="operator-list__content-item"
            >
            </a-tab-pane>
        </a-tabs>

        <AllOperatorsAccordion
            class="mb-1"
            v-for="(operator, index) in filteredOperatorList"
            :key="operator.resourceId"
            :item="operator"
            :index="index"
            :disabled="operatorCallModel.isDisabled"
            :is-admin-connected-to-operator-call="
                isAdminConnectedToOperatorCall
            "
            :listening-extension="monitoringExtension"
            :listening-call-id="monitoringCallId"
            @set:filter="emit('set:filter', $event)"
            @configure:action="toggleFilterModal"
            @listeingExtnetion="onOperatorListen"
        />

        <EmptyData v-show="isEmptyData" />

        <OperatorQueueModal
            v-if="modalConfigureData.visible"
            v-bind="modalConfigureData"
            v-on="modalFilterListeners"
            :agent-id="modalOperatorId"
        />
    </div>
</template>

<script>
import { mapState } from "vuex";
import EmptyData from "@/components/partials/common/EmptyData.vue";
import AllOperatorsAccordion from "@/components/pages/AdminHomePage/Widgets/OperatorsList/Accordions/AllOperators.vue";
import { FILTER_ITEMS, SEARCH_ITEMS } from "@/data/pages/Dashboard/Admin/index";
import OperatorQueueModal from "./OperatorQueueModal";
import {
    MODAL_CONFIGURE_DATA,
    MODAL_CONFIGURE_MODEL,
} from "@/data/components/pages/AdminHomePage/Widgets/OperatorsList";
import finasseAgent from "../../../../../services/finesse-agent";

export default {
    name: "ControlGroupOperatorsList",
    emits: [
        "configure:action",
        "update:tab",
        "edit:action",
        "remove:action",
        "input",
    ],
    components: {
        OperatorQueueModal,
        EmptyData,
        AllOperatorsAccordion,
    },
    model: {
        prop: "value",
        event: "input",
    },
    props: {
        value: {
            type: String,
            required: true,
            default: "",
        },
        tabLabels: {
            type: Object,
            required: true,
            default: () => ({}),
        },
        isEmptyData: {
            type: Boolean,
            required: true,
            default: false,
        },
        stats: {
            type: Array,
            default: () => [],
        },
        operatorList: {
            type: Array,
            required: true,
            default: () => [],
        },
    },
    data: () => ({
        categoryInternal: "name",
        operatorCallModel: {
            isDisabled: false,
        },
        isAdminConnectedToOperatorCall: false,
        searchItems: SEARCH_ITEMS,
        filter: new FILTER_ITEMS(),
        modalConfigureData: new MODAL_CONFIGURE_DATA(),

        callMonitorListener: null,
        monitoringExtension: null,
        monitoringCallId: null,

        modalOperatorId: "",
    }),
    computed: {
        filteredOperatorList() {
            if (this.operatorList.length === 0) return this.stats;

            const onlineUsersStat = this.stats.filter((stat) =>
                this.operatorList.some(
                    (operator) => operator.loginId === stat.resourceId
                )
            );
            const offlineUsersStat = this.operatorList
                .filter(
                    (user) =>
                        !onlineUsersStat.some(
                            (stat) => stat.resourceId === user.loginId
                        )
                )
                .map((user) => ({
                    resourceId: user.loginId,
                    resourceName: user.fullName,
                    state: "Оффлайн",
                }));

            return [...onlineUsersStat, ...offlineUsersStat];
        },
        activeTabKey: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit("input", value);
            },
        },
        clientTypesList() {
            // ToDo Не сделал интернационализацию так как данные будут приходить с справочника бэк энда
            return [{ name_uz: "Тип клиентов", id: "" }];
        },
        clientStatusesList() {
            // ToDo Не сделал интернационализацию так как данные будут приходить с справочника бэк энда
            return [{ name_uz: "Статус", id: "" }];
        },
        modalFilterListeners() {
            return {
                reset: this.resetForm,
                close: this.toggleFilterModal,
                submit: this.submitForm,
                input: this.onFormInput,
            };
        },
        ...mapState({
            userTeams(state) {
                return [...state.userTeams];
            },
        }),
    },
    methods: {
        onFormInput(key, value) {
            this.modalConfigureData.model[key] = value;
        },
        toggleFilterModal(agentId) {
            if (agentId) {
                this.modalOperatorId = agentId;
            } else {
                this.modalOperatorId = "";
            }
            this.modalConfigureData.visible = !this.modalConfigureData.visible;
        },
        resetForm() {
            this.modalConfigureData.model = new MODAL_CONFIGURE_MODEL();
        },
        submitForm() {},
        emit(name, $value) {
            this.$emit(name, $value);
        },
        onOperatorCallSilentMonitor() {
            this.callMonitorListener = finasseAgent.xmpp.messages$.subscribe(
                (el) => {
                    this.monitoringCallId = el.id;
                }
            );
        },
        onOperatorListen(extentsion) {
            this.monitoringExtension = extentsion;
        },
    },
    mounted() {
        this.onOperatorCallSilentMonitor();
    },
    beforeDestroy() {
        if (this.callMonitorListener) {
            this.callMonitorListener.unsubscribe();
        }
    },
};
</script>

<style lang="scss">
.operator-list {
    &__heading {
        color: var(--color-black);
        font-size: 28px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-bottom: 25px;
    }
    &__tabs {
        .ant-tabs {
            &-ink-bar {
                border-width: 1px;
            }

            &-bar {
                // Пришлось использовать important
                // TODO Избавиться в будущем
                margin-bottom: 22px !important;
            }

            &-tab {
                font-size: 20px;
                line-height: 20px;
                font-weight: 700;
                color: var(--color-gray-light);
                padding: 0 0 24px 0;
                margin-right: 0;
                font-family: "Gilroy", sans-serif;

                &:not(:first-child) {
                    margin-left: 62px;
                }

                &.ant-tabs-tab-active {
                    color: var(--color-dark) !important;
                    font-weight: bold !important;
                }
            }
        }
    }
}
</style>
