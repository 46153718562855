<template>
    <section class="empty-data">
        <div class="empty-data__wrap">
            <img src="@/assets/img/SweetJam-256px-19.webp" alt="" class="empty-data__object">
            <div class="empty-data__text-wrap">
                <p class="empty-data__text">{{ $t("errors.emptyData") }}</p>
            </div>
        </div>
    </section>
</template>
<script>
export default {
    name: 'EmptyData',
    components: {},
    props: {
        isChat: Boolean,
        calling: Boolean
    }
}
</script>
<style lang="scss">
.empty-data {
    transition: background-color .25s;
    display: flex;
    flex-direction: column;
    width: 100%;
    flex-grow: 1;
    padding-top: 224px;
    &__wrap {
        display: flex;
        margin: auto;
    }
    &__object {
        width: 256px;
        height: 256px;
    }
    &__text-wrap {
        margin-left: 18px;
        margin-top: 80px;
    }
    &__text {
        margin: 0 0 18px;
        padding: 13px 32px 12px 32px;
        background-color: var(--color-white);
        border-radius: 24px;
        color: var(--color-gray);
        font-weight: 500;
        font-size: 24px;
        line-height: 29px;
        position: relative;
        z-index: 0;
        &:before {
            content: '';
            z-index: -1;
            position: absolute;
            bottom: 1px;
            left: -18px;
            background: url('../../../assets/img/bubble-tail.svg') center/contain no-repeat;
            width: 56px;
            height: 24px;
        }
    }
}

</style>
