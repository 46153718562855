import {FilterItem, SearchItem} from "@/types/pages/Dashboard/Admin/Admin.type";

export const SEARCH_ITEMS: SearchItem[] = [
    {
        name: 'common.everywhere',
        value: 'name',
        short: 'common.everywhere'
    },
];

export const FILTER_ITEMS = (): FilterItem => ({
    status: "",
    type: ""
});
