<template>
    <div class="script-item">
        <div class="script-item__actions">
            <slot name="additional-actions" />

            <template v-if="isTalking">
                <AppButton
                    v-if="isAdminConnectedToOperatorCall"
                    class="connect"
                    theme="danger"
                    size="large"
                    @click="disconnect"
                >
                    <span>{{ $t("common.disconnect") }}</span>
                </AppButton>
                <AppButton
                    v-else
                    class="connect"
                    :theme="getThemeByStatus"
                    size="large"
                    :disabled="loading"
                    @click="connect"
                >
                    <span>{{ $t("common.connect") }}</span>
                </AppButton>
            </template>

            <div
                class="script-item__button-wrap"
                v-clickaway="close"
                v-if="actions.length"
            >
                <AppButton
                    class="script-item__button"
                    theme="primary-outline"
                    square
                    size="small"
                    @click="toggle"
                >
                    <AppIcon icon="dots-horizontal" />
                </AppButton>

                <transition name="dialer">
                    <div
                        v-show="opened"
                        class="script-item__menu-actions script-item__menu-actions--direction-bottom"
                    >
                        <action-button
                            v-if="actions.includes('edit')"
                            icon="edit-alt"
                            text="common.table.edit"
                            @on:click="emit('edit:action')"
                        />
                        <action-button
                            v-if="actions.includes('copy')"
                            icon="copy"
                            text="common.table.copy"
                            @on:click="emit('copy:action')"
                        />
                        <action-button
                            v-if="actions.includes('remove')"
                            icon="trash-alt"
                            text="common.table.delete"
                            @on:click="emit('remove:action')"
                        />
                        <action-button
                            v-if="actions.includes('configure')"
                            class="script-item__menu-actions--configure"
                            icon="settings-grey"
                            text="control-group.queue"
                            @on:click="emit('configure:action')"
                        />
                        <action-button
                            v-if="actions.includes('disable') && isReady && !isCurrentUser"
                            class="script-item__menu-actions--configure theme-error"
                            icon="status-not-ready"
                            text="control-group.disable"
                            @on:click="onDisable"
                        />
                    </div>
                </transition>
            </div>
        </div>
    </div>
</template>

<script>
import AppIcon from "@/components/partials/AppIcon.vue";
import ActionButton from "@/components/admin/Branch/AccordionHelpers/ActionButton.vue";
import AppButton from "@/components/partials/AppButton.vue";
import clickaway from "@/directives/clickaway";

export default {
    components: {
        AppButton,
        AppIcon,
        ActionButton,
    },
    emits: [
        "edit:action",
        "copy:action",
        "remove:action",
        "configure:action",
        "open-menu:connect",
        "open-menu:disconnect",
        "disable"
    ],
    directives: { clickaway },
    props: {
        actions: Array,
        isOpen: Boolean,
        disabled: {
            type: Boolean,
            required: true,
            default: false,
        },
        isAdminConnectedToOperatorCall: {
            type: Boolean,
            required: true,
            default: false,
        },
        isTalking: {
            type: Boolean,
            default: false,
        },
        isReady: {
            type: Boolean,
            default: false,
        },
        loading: {
            type: Boolean,
            default: false,
        },
        username: {
            type: String,
            default: "",
        },
    },
    data: () => ({
        opened: false,
    }),
    computed: {
        getThemeByStatus() {
            return this.disabled ? "gray" : "primary";
        },

        isCurrentUser() {
            const username = this.$store.state.auth.username;
            return username === this.username
        }
    },
    methods: {
        toggle() {
            if (this.opened) this.close();

            this.open();
        },
        open() {
            this.opened = true;
        },
        close() {
            this.opened = false;
        },
        emit(name) {
            this.$emit(name);
        },
        connect() {
            if (this.disabled) {
                return;
            }

            this.$emit("open-menu:connect");
        },
        disconnect() {
            this.$emit("open-menu:disconnect");
        },
        onDisable() {
            this.opened = false
            this.$emit('disable')
        }
    },
};
</script>

<style lang="scss">
.script-item__menu-actions {
    .app-icon {
        width: 20px;
        height: 20px;
        color: #949494;
        margin-right: 0;
    }

    .theme-error {
        .app-icon {
            color: var(--color-danger-light);
        }
    }
}
</style>

<style
    src="@/assets/scss/modules/components/admin/Branch/AccordionHelpers/HeaderMenuActions.scss"
    lang="scss"
    scoped
></style>
