<template>
    <ModalWrapper
        ref="modal"
        custom-close
        transparent
        @overlay-click="close"
        @close-modal="close"
    >
        <div
            class="modal-window operator-queue-modal"
            :class="modalWindowClass ? `modal-window--${modalWindowClass}` : ''"
        >
            <div class="modal-window__close" @click="close">
                <AppIcon icon="close" />
            </div>
            <div class="modal-window__header">
                <AppIcon v-if="icon" :icon="icon" />
                {{ $t(name) }}
            </div>
            <div class="modal-window__body">
                <div class="operator-queue-modal__row">
                    <div class="operator-queue-modal__col">
                        <OperatorQueueModalList
                            :title="$t('control-group.activeQueue')"
                            :list="activeList"
                            :is-active-list="true"
                            :loading="listLoading"
                            @remove="onRemove"
                        />
                    </div>
                    <div class="operator-queue-modal__col">
                        <OperatorQueueModalList
                            :title="$t('control-group.inActiveQueue')"
                            :list="inActiveList"
                            :is-active-list="false"
                            :loading="listLoading"
                            @add="onAdd"
                        />
                    </div>
                </div>
            </div>
        </div>
    </ModalWrapper>
</template>

<script>
import ModalWrapper from "@/components/partials/ModalWrapper";
import AppIcon from "@/components/partials/AppIcon";
import OperatorQueueModalList from "./modal/operator-queue-modal-list.vue";

import finasseAgent from "../../../../../services/finesse-agent";

export default {
    name: "OperatorQueueModal",

    components: {
        ModalWrapper,
        AppIcon,
        OperatorQueueModalList,
    },

    props: {
        modalWindowClass: String,
        name: String,
        icon: String,
        clearable: {
            type: Boolean,
            default: true,
        },
        loading: Boolean,
        errorMessages: Object,
        renderInputs: Array,
        model: Object,
        agentId: String,
    },

    data: () => ({
        activeList: [],
        inActiveList: [],

        listLoading: false,
    }),

    methods: {
        onInput(key, value) {
            this.$emit("input", key, value);
        },

        close() {
            this.$emit("close");
        },

        submit() {
            this.$emit("submit");
        },

        reset() {
            this.$emit("reset");
        },

        closeModal() {
            this.$refs.modal.close();
        },

        loadQueues() {
            this.listLoading = true;
            finasseAgent.finesse
                .GetUserInfoWithCSQ(this.agentId)
                .then((response) => {
                    if (response.haveCsqs) {
                        this.activeList = response.haveCsqs;
                    }
                    if (response.dontHaveCsqs) {
                        this.inActiveList = response.dontHaveCsqs;
                    }
                })
                .catch((e) => {
                    console.log(e);
                })
                .finally(() => {
                    this.listLoading = false;
                });
        },

        onRemove(item) {
            this.listLoading = true;
            finasseAgent.finesse
                .DeleteAgentFromCSQ(this.agentId, item.id)
                .then((response) => {
                    if (!response) {
                        this.$store.commit("notifications/error", "Не удалось выполнить действие");
                    }
                    this.loadQueues();
                })
                .catch((e) => {
                    this.$store.commit("notifications/error", e);
                    this.listLoading = false;
                });
        },

        onAdd(item) {
            this.listLoading = true;
            finasseAgent.finesse
                .AddAgentToCSQ(this.agentId, item.id)
                .then((response) => {
                    if (!response) {
                        this.$store.commit("notifications/error", "Не удалось выполнить действие");
                    }
                    this.loadQueues();
                })
                .catch((e) => {
                    this.$store.commit("notifications/error", e);
                    this.listLoading = false;
                });
        },
    },

    mounted() {
        if (this.agentId) {
            this.loadQueues();
        }
    },
};
</script>

<style lang="scss">
.operator-queue-modal {
    width: 940px;
}

.operator-queue-modal__row {
    display: flex;
    gap: 24px;
}

.operator-queue-modal__col {
    flex: 1;
}
</style>
