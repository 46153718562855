<template>
    <div class="time-card">
        <div class="time-card__text" :class="[theme]">
            <AppIcon class="time-card-icon" :class="{ 'no-stroke': noIconStroke }" :icon="icon"/>
            {{ $t(title) }}
        </div>

        <div class="time-card__time">
            {{ time }}
        </div>
    </div>
</template>

<script>
import AppIcon from "@/components/partials/AppIcon.vue";

export default {
    components: {AppIcon},
    name: "GroupControlPhoneCard",
    props: {
        icon: {
            type: String,
            required: true,
            default: '',
        },
        title: {
            type: String,
            required: true,
            default: ''
        },
        time: {
            type: String,
            required: true,
            default: ''
        },
        theme: {
            type: String,
            required: false,
            default: ''
        },
        noIconStroke: {
            type: Boolean,
            default: false
        }
    }
}
</script>

<style lang="scss">
.time-card {
    height: 110px;
    width: 190px;
    padding: 18px 24px;
    border-radius: 8px;
    background: var(--color-white-bg);
    display: flex;
    flex-direction: column;
    gap: 16px;

    &__text {
        display: flex;
        align-items: center;
        gap: 8px;
        height: 30px;

        color: var(--color-gray-light);
        font-size: 12px;
        font-weight: 500;

        svg {
            width: 20px;
            height: 20px;
        }

        &.error {
            color: var(--color-danger-light);
        }

        &.success {
            color: var(--color-success)
        }

        &.primary {
            color: var(--color-primary)
        }
    }
    &__time {
        color: var(--color-dark);
        font-size: 22px;
        font-style: normal;
        font-weight: 500;
        line-height: 28px;
        letter-spacing: 0.11px;
    }
}
</style>
