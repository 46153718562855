<script>
import AppIcon from "@/components/partials/AppIcon.vue";

export default {
    components: {
        AppIcon
    },
    props: {
        icon: {
            type: String,
            required: true,
            default: ""
        },
        text: {
            type: String,
            required: true,
            default: ""
        },
    },
    render(h) {
        return h('div', {
            class: 'script-item__menu-item',
            on: {
                click: () => this.$emit('on:click')
            }
        }, [
            this.$slots['left-side-text'],
            h(AppIcon, {
                props: {
                    icon: this.icon
                }
            }),
            this.$t(this.text),
            this.$slots.default
        ]);
    }
}

</script>
