<template>
    <div class="group-card">
        <div class="group-card__items">
            <div class="group-card__items-call_number">
                <img
                    class="group-card__items-call_number-icon"
                    :src="icon"
                    alt=""
                />
                <div class="group-card__items-call_number-text">
                    <span>{{ phone }}</span>
                    <span class="group-card__items-call_number-text-sub">{{
                        phoneSub
                    }}</span>
                </div>
            </div>
            <div class="group-card__items-statistics">
                <div class="group-card__items-statistics-attempts">
                    <group-control-attempt-card
                        title="control-group.waitCount"
                        :time="waitCount.toString()"
                    />
                    <group-control-attempt-card
                        v-if="!isChat"
                        title="control-group.longestWait"
                        :time="longestWait | timeFromMS"
                    />
                </div>
                <div class="group-card__items-statistics-time">
                    <group-control-phone-card
                        :title="
                            isChat ? 'control-group.allChats' : 'control-group.allContacts'
                        "
                        :time="allContacts.toString()"
                        :icon="isChat ? 'all-chats' :'call-2'"
                    />
                    <group-control-phone-card
                        :title="isChat ? 'control-group.servedChats' : 'control-group.servedContacts'"
                        :time="servedContacts.toString()"
                        :icon="isChat ? 'served-chats' : 'phone-number'"
                        :no-icon-stroke="isChat"
                    />
                    <group-control-phone-card
                        title="control-group.lostRate"
                        :time="lostRate"
                        icon="percentage"
                    />
                    <group-control-phone-card
                        title="control-group.operatorsOnSystem"
                        :time="operatorsOnSystem.toString()"
                        icon="active-operators"
                        theme="primary"
                    />
                    <group-control-phone-card
                        title="control-group.notReadyOperators"
                        :time="notReadyOperators.toString()"
                        icon="inactive-operators"
                        theme="error"
                        no-icon-stroke
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import AppIcon from "@/components/partials/AppIcon.vue";
import GroupControlPhoneCard from "@/components/pages/AdminHomePage/Widgets/PhoneCard.vue";
import GroupControlAttemptCard from "@/components/pages/AdminHomePage/Widgets/AttemptCard.vue";
import filterDate from "@/mixins/FilterDate";

export default {
    name: "ControlGroupCard",
    components: { GroupControlAttemptCard, GroupControlPhoneCard, AppIcon },
    mixins: [filterDate],
    props: {
        isChat: {
            type: Boolean,
            default: false,
        },
        icon: {
            type: String,
            required: true,
            default: "",
        },
        phone: {
            type: String,
            required: true,
            default: "",
        },
        phoneSub: {
            type: String,
            required: false,
            default: "",
        },
        theme: {
            type: String,
            default: "",
        },
        waitCount: {
            type: Number,
            default: 0,
        },
        allContacts: {
            type: Number,
            default: 0,
        },
        longestWait: {
            type: Number,
            default: 0,
        },
        servedContacts: {
            type: Number,
            default: 0,
        },
        operatorsOnSystem: {
            type: Number,
            default: 0,
        },
        notReadyOperators: {
            type: Number,
            default: 0,
        },
    },
    computed: {
        lostRate() {
            if (this.allContacts > 0 || this.servedContacts > 0) {
                const lost = this.allContacts - this.servedContacts;
                const rate = (lost / this.allContacts) * 100;
                return `${Math.floor(rate)}%`;
            }

            return "0%";
        },
    },
};
</script>

<style lang="scss">
$color-default: #397af5;
$color-success: #00cc56;
$color-error: #ff4921;

@mixin iconStyles($color) {
    &:not([fill="none"]) {
        fill: $color;
    }
    [stroke] {
        stroke: $color;
    }
    [fill]:not([fill="none"]) {
        fill: $color;
    }
}

.group-card {
    padding: 0 16px;
    background: #ffffff;
    height: 138px;
    font-family: "Gilroy", sans-serif;
    border-radius: 12px;

    &__items {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 36px;
        height: 100%;
        overflow-x: scroll;

        &-call_number {
            display: flex;
            align-items: center;
            gap: 12px;
            font-size: 24px;
            line-height: 29px;
            font-weight: 700;
            color: var(--color-card-black);
            white-space: nowrap;

            &-icon {
                height: 30px;
                width: 30px;
                @include iconStyles($color-default);

                &.success {
                    @include iconStyles($color-success);
                }

                &.error {
                    @include iconStyles($color-error);
                }
            }

            &-text {
                display: flex;
                flex-direction: column;
                gap: 4px;
                &-sub {
                    color: var(--color-gray-lighten);
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                }

                span:first-child {
                    width: 280px;
                    white-space: wrap;
                }
            }
        }
        &-statistics {
            display: flex;
            align-items: center;
            gap: 36px;

            &-attempts {
                display: flex;
                align-items: center;
                gap: 42px;
                p {
                    margin-bottom: 6px;
                }
            }

            &-time {
                display: flex;
                align-items: center;
                gap: 14px;
            }
        }
    }
}
</style>
