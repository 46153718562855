<template>
    <article class="control-group-wrapper">
        <div class="control-group-wrapper__header" @click="isVisible = !isVisible">
            <AppIcon class="control-group-wrapper__header-icon" :icon="icon"/>
            <span class="control-group-wrapper__header-text"> {{ $t(title) }}</span>
        </div>

        <transition name="opacity">
            <div class="control-group-wrapper__content" v-show="isVisible">
                <slot name="card"></slot>
            </div>
        </transition>
    </article>
</template>

<script>
import AppIcon from "@/components/partials/AppIcon.vue";

export default {
    name: "ControlGroupArticle",
    components: {AppIcon},
    props: {
        title: {
            type: String,
            required: true,
            default: '',
        }
    },
    data: () => ({
        isVisible: true
    }),
    computed: {
        icon() {
            return this.isVisible ? 'chevron-circle-down' : 'chevron-circle-top';
        }
    }
}
</script>

<style lang="scss">
.control-group-wrapper {
    margin-bottom: 65px;

    &__header {
        display: flex;
        align-items: center;
        cursor: pointer;
        margin-bottom: 25px;
        color: var(--color-black);
        font-size: 22px;
        font-weight: 500;

        &-icon {
            margin-right: 12px;
            circle {
                fill: #EBEBF4 !important;
            }

            path {
                fill: #0062FF !important;
            }
        }

      &-text {
        color: var(--color-black);
        font-size: 22px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
    }
    &__content {
        display: flex;
        flex-direction: column;
        gap: 8px;
    }
}
</style>
