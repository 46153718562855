<template>
    <div class="attempt-card" :style="{width: width}">
        <p class="attempt-card__text"> {{ $t(title) }} </p>
        <p class="attempt-card__time"> {{ time }} </p>
    </div>
</template>

<script>
export default {
    name: "GroupControlAttemptCard",
    props: {
        title: {
            type: String,
            required: true,
            default: ''
        },
        time: {
            type: String,
            required: true,
            default: ''
        },
        width: {
            type: String,
        }
    }
}
</script>

<style lang="scss" scoped>
.attempt-card {
    display: flex;
    flex-direction: column;

    &__text {
        margin: 0;
        color: var(--color-gray-lighten);
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }
    &__time {
        margin: 0;
        color: var(--color-dark);
        font-size: 22px;
        font-style: normal;
        font-weight: 500;
        letter-spacing: 0.11px;
    }
}
</style>
