<template>
    <div class="accordion-header">
        <p class="accordion-header__order">{{ info.order }}</p>
        <p class="accordion-header__text">{{ info.name }}</p>
        <div class="accordion-header__chip default-tag" :class="getState(info.status)">
            {{ info.status }}
        </div>
    </div>
</template>

<script>
import AppIcon from "@/components/partials/AppIcon.vue";

export default {
    components: { AppIcon },
    props: {
        info: {
            type: Object,
            required: true,
            default: () => ({}),
        },
    },

    methods: {
        getState(name) {
            const states = {
                Готов: "default",
                "Не готов": "danger",
                Разговор: "default outline",
                Зарезервирован: "warning outline",
                Работает: "accent",
                "В системе": "primary",
                "Оффлайн": "danger outline"
            };
            const keywords = ["default-tag--theme-", "default-tag--"];
            const keys = (states[name] || "warning").split(" ");
            return keys.map((key, idx) => keywords[idx] + key).join(" ");
        },
    },
};
</script>

<style lang="scss">
.accordion-header {
    display: flex;
    align-items: center;
    font-family: Gilroy;

    &__order {
        color: var(--color-gray-lighten);
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: 0.1px;
        margin: 0 20px 0 0;
    }

    &__text {
        color: var(--color-dark);
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: 0.1px;
        margin: 0 32px 0 0;
    }

    &__chip {
        display: flex;
        align-items: center;
        gap: 6px;
        border-radius: 50px;
        padding: 3px 14px;
        margin: 0 12px 0 0;
        font-size: 16px;
        line-height: normal;

        &::before {
            display: inline-block;
            content: "";
            width: 6px;
            height: 6px;
            border-radius: 100%;
            background-color: currentColor;
        }
    }

    &__rating {
        display: flex;
        align-items: center;
        gap: 4px;
        color: var(--color-dark);
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.08px;
        margin: 0 28px 0 0;

        &__icon {
            height: 14px;
            width: 14px;

            path {
                fill: #00cc56 !important;
            }
        }
    }

    &__icon {
        &:not(.no-stroke) [fill]:not([fill="none"]) {
            fill: #edf2ff;
        }

        path {
            fill: #397af5 !important;
        }
    }
}
</style>
