<template>
    <div class="group-control-accordions">
        <!-- Accordion header -->
        <div class="group-control-accordions__header">
            <div class="group-control-accordions__header-info">
                <accordion-header
                    :info="{
                        order: index + 1,
                        name: item.resourceName,
                        status: item.state,
                    }"
                />
            </div>

            <div class="group-control-accordions__header-actions">
                <header-menu-actions
                    class="group-control-accordions__header-actions__script_item"
                    :actions="['configure', 'disable']"
                    :is-open="isOpen"
                    :disabled="disabled"
                    :is-admin-connected-to-operator-call="
                        operatorExtension === listeningExtension
                    "
                    :is-talking="isTalking"
                    :is-ready="item.resourceState === 'Ready'"
                    :username="item.resourceId"
                    :loading="operatorExtensionLoading"
                    @configure:action="emit('configure:action', item.resourceId)"
                    @open-menu:connect="onOperatorListen"
                    @open-menu:disconnect="onOperatorStopListen"
                    @disable="onOperatorDisable"
                />
            </div>
        </div>

        <div class="group-control-accordions__content">
            <div class="group-control-accordions__content-info">
                <!-- <accordion-column title="control-group.group" :value="'Телемаркетинг'" size="full"/> -->
                <accordion-column
                    title="control-group.workTime"
                    :value="item.logonDuration"
                    size="full"
                />
                <accordion-column
                    title="control-group.averageCallTime"
                    :value="item.avgTalkDuration | timeFromMS"
                    size="full"
                />
                <accordion-column
                    title="control-group.notReadyTime"
                    theme="error"
                    :value="item.totalNotReadyTime | timeFromMS"
                    size="full"
                />
                <accordion-column
                    title="control-group.averageHoldTime"
                    :value="item.avgHoldDuration | timeFromMS"
                    size="full"
                    active=""
                />
                <!-- <accordion-column title="control-group.readyTime" theme="success" :value="'05:20:08'" size="full"/> -->
            </div>
        </div>
    </div>
</template>

<script>
import AccordionHeader from "@/components/pages/AdminHomePage/Widgets/OperatorsList/Accordions/Accordion/Header.vue";
import AccordionColumn from "@/components/pages/AdminHomePage/Widgets/OperatorsList/Accordions/Accordion/Column.vue";
import HeaderMenuActions from "@/components/pages/AdminHomePage/Widgets/OperatorsList/Accordions/Accordion/HeaderMenuActions.vue";

import filterDate from "@/mixins/FilterDate";
import { AGENT_STATES } from "@contact-center/asakabank-cisco-finesse";
import finasseAgent from "../../../../../../services/finesse-agent";
import { getDataFromStore } from "@/helpers/api";

export default {
    emits: ["set:filter", "edit:action", "remove:action"],
    mixins: [filterDate],
    components: {
        HeaderMenuActions,
        AccordionHeader,
        AccordionColumn,
    },
    props: {
        item: {
            type: [Object],
            required: true,
            default: () => ({}),
        },
        index: {
            type: Number,
            required: true,
            default: 0,
        },
        disabled: {
            type: Boolean,
            required: true,
            default: false,
        },
        isAdminConnectedToOperatorCall: {
            type: Boolean,
            required: true,
            default: false,
        },
        listeningExtension: {
            type: String,
        },
        listeningCallId: {
            type: String,
        },
    },
    data() {
        return {
            isOpen: false,
            listening: false,
            operatorExtension: "",
            operatorExtensionLoading: false,
        };
    },
    methods: {
        emit(name, $value) {
            this.$emit(name, $value);
        },
        toggleAccordion() {
            this.isOpen = !this.isOpen;
        },
        onOperatorDisable() {
            finasseAgent.finesse
                .ChangeState(AGENT_STATES.NOT_READY, 0, this.item.resourceId)
                .catch((e) => {
                    console.log(e);
                });
        },

        onOperatorListen() {
            if (this.operatorExtension) {
                return this.monitorOperator()
            }
            this.operatorExtensionLoading = true;
            finasseAgent.finesse
                .GetUserInfoWithCSQ(this.item.resourceId)
                .then((response) => {
                    this.operatorExtension = response.extension;
                    this.monitorOperator()
                })
                .catch((e) => {
                    console.log(e);
                })
                .finally(() => {
                    this.operatorExtensionLoading = false;
                });
        },

        monitorOperator() {
            const { username } = getDataFromStore();
            if (username && this.operatorExtension) {
                console.log(this.operatorExtension);
                finasseAgent.finesse
                    .StartSilentMonitor(username, this.operatorExtension)
                    .then(() => {
                        this.$emit("listeingExtnetion", this.operatorExtension);
                    })
                    .catch((e) => {
                        console.log(e);
                    });
            }
        },

        onOperatorStopListen() {
            finasseAgent.finesse
                .DropCall(this.listeningCallId)
                .then((response) => {
                    console.log("CALL DROP", response);
                    this.$emit("listeingExtnetion", null);
                })
                .catch((e) => {
                    console.log(e);
                })
        },
    },
    computed: {
        isTalking() {
            if (this.item.resourceState === "Talking") {
                if (this.listeningExtension) {
                    return this.listeningExtension === this.operatorExtension;
                }
                return true;
            }
            return false;
        },
    },
};
</script>

<style
    src="@/assets/scss/modules/components/pages/AdminHomePage/Widgets/OperatorsList/Accordions/AllOperators.scss"
    lang="scss"
></style>
