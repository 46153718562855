<template>
    <div class="operator-queue-modal-list">
        <div class="operator-queue-modal-list__title">
            {{ title }}
        </div>
        <div class="operator-queue-modal-list__list">
            <OperatorQueueModalListItem
                v-for="item in operatorList"
                :key="item.id"
                :item="item"
                :icon="itemIcon"
                :loading="loading"
                :theme="iconTheme"
                @click="onClick"
            />
        </div>
    </div>
</template>

<script>
import AppIcon from "@/components/partials/AppIcon";
import OperatorQueueModalListItem from "./operator-queue-modal-list-item.vue";

export default {
    name: "OperatorQueueModalList",

    components: {
        AppIcon,
        OperatorQueueModalListItem,
    },

    props: {
        title: {
            type: String,
            default: "",
        },

        isActiveList: {
            type: Boolean,
            default: false,
        },

        loading: {
            type: Boolean,
            default: false,
        },

        list: {
            type: Array,
        },
    },

    methods: {
        onClick(item) {
            if (this.isActiveList) {
                this.$emit("remove", item);
            } else {
                this.$emit("add", item);
            }
        },
    },

    computed: {
        itemIcon() {
            if (this.isActiveList) {
                return "trash-alt";
            }
            return "plus";
        },

        operatorList() {
            if (this.loading) {
                const skeleton = Array(12).fill({})
                return skeleton
            }
            return this.list
        },

        iconTheme() {
            if (this.isActiveList) {
                return "error"
            }
            return "primary"
        }
    },
};
</script>

<style lang="scss">
.operator-queue-modal-list__title {
    font-size: 20px;
    line-height: 24px;
    font-weight: 500;
    margin-bottom: 20px;
}

.operator-queue-modal-list__list {
    border-radius: 12px;
    border: 1px solid var(--color-border);
    padding: 8px;

    overflow-y: scroll;
    height: 456px;

    position: relative;
}
</style>
