var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"group-control"},[_c('h1',{staticClass:"group-control-heading"},[_vm._v(_vm._s(_vm.$t("control-group.title")))]),_c('control-group-article',{attrs:{"title":"control-group.totalCallStatistic"},scopedSlots:_vm._u([{key:"card",fn:function(){return _vm._l((_vm.cardsArray),function({
                        phone,
                        phoneSub,
                        icon,
                        theme,
                        waitCount,
                        allContacts,
                        longestWait,
                        servedContacts,
                        operatorsOnSystem,
                        notReadyOperators,
                    },index){return _c('control-group-card',{key:index,attrs:{"phone":phone,"phoneSub":phoneSub,"icon":icon,"theme":theme,"wait-count":waitCount,"all-contacts":allContacts,"longest-wait":longestWait,"served-contacts":servedContacts,"operators-on-system":operatorsOnSystem,"not-ready-operators":notReadyOperators}})})},proxy:true}])}),_c('control-group-article',{attrs:{"title":"control-group.totalChatStatistic"},scopedSlots:_vm._u([{key:"card",fn:function(){return _vm._l((_vm.chatsArray),function({
                        title,
                        icon,
                        waitCount,
                        allChats,
                        servedChats,
                        readyAgents,
                        notReadyAgents,
                    },index){return _c('control-group-card',{key:index,attrs:{"is-chat":"","phone":title,"icon":icon,"wait-count":waitCount,"all-contacts":allChats,"served-contacts":servedChats,"operators-on-system":readyAgents,"not-ready-operators":notReadyAgents}})})},proxy:true}])}),(_vm.operatorList.length > 0)?_c('control-group-operators-list',{attrs:{"operatorList":_vm.operatorList,"is-empty-data":false,"tab-labels":_vm.tabLabels,"stats":_vm.resourceStats},on:{"update:tab":_vm.loadOperators},model:{value:(_vm.activeTabKey),callback:function ($$v) {_vm.activeTabKey=$$v},expression:"activeTabKey"}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }