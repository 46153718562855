<template>
    <div
        class="operator-queue-modal-list-item"
        :class="{ 'loading-active': loading }"
        @click="onClick"
    >
        <div class="operator-queue-modal-list-item__text">
            <div v-if="loading" class="skeleton" :style="{ height: '20px' }" />
            <template v-else>
                {{ item.name }}
            </template>
        </div>
        <div
            v-if="icon && !loading"
            class="operator-queue-modal-list-item__btn"
            :class="theme"
        >
            <AppIcon :icon="icon" />
        </div>
    </div>
</template>

<script>
import AppIcon from "@/components/partials/AppIcon";

export default {
    name: "OperatorQueueModalListItem",

    components: {
        AppIcon,
    },

    props: {
        item: {
            type: Object,
            default: () => ({ name: "test" }),
        },

        icon: {
            type: String,
            default: "",
        },

        theme: {
            type: String,
            default: "",
        },

        loading: {
            type: Boolean,
            default: false,
        },
    },

    methods: {
        onClick() {
            this.$emit("click", this.item);
        },
    },
};
</script>

<style lang="scss">
.operator-queue-modal-list-item {
    padding: 12px 16px;
    border-radius: 8px;

    display: flex;
    align-items: center;
    gap: 8px;
    justify-content: space-between;

    &:hover {
        background: #edf2ff;
        cursor: pointer;

        .error {
            color: var(--color-danger);
        }

        .primary {
            color: var(--color-primary);
        }
    }

    &.loading-active {
        pointer-events: none;

        .operator-queue-modal-list-item__text {
            flex-grow: 1;
        }
    }
}

.operator-queue-modal-list-item__text {
    font-size: 16px;
    line-height: 32px;
    font-weight: 500;
}

.operator-queue-modal-list-item__btn {
    color: var(--color-gray-lighten);
}
</style>
