import {ModalFilterModelType} from "@/types/pages/Customers/Customers.type";
import {subjectTypeArray} from "@/config/subjectType";

type InputType = 'input' | 'double-date' | 'select' | 'tree';

type RenderInput = {
    key: string;
    name: string;
    type: InputType;
    items: null | any;
    keys?: [string, string];
    flattenItems?: [];
};

type ModalFilterDataType = {
    renderInputs: RenderInput[];
    model: ModalFilterModelType;
    errorMessages: Record<string, string>;
    icon: string;
    name: string;
    "modal-window-class": string;
    visible: boolean;
};

export const MODAL_CONFIGURE_MODEL = (): ModalFilterModelType => ({
    username: '',
    phone: '',
    source_id: '',
    product_id: [],
    language_id: '',
    is_company: '',
    created_at_from: '',
    created_at_to: '',
});

export const MODAL_CONFIGURE_DATA = (): ModalFilterDataType => ({
    renderInputs: [
        {
            key: "product_id",
            name: "common.form.operatorsGroup",
            type: "tree",
            flattenItems: [],
            items: []
        },
        {
            key: "is_company",
            name: "common.form.clientsType",
            type: "select",
            items: subjectTypeArray
        },
        {
            key: "language_id",
            name: "common.form.operatorStatus",
            type: "select",
            items: []
        },
    ],
    model: MODAL_CONFIGURE_MODEL(),
    errorMessages: {},
    icon: "settings-outline",
    name: "control-group.configureModal.title",
    "modal-window-class": "single-column",
    visible: false,
});
